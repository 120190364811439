import { Drawer, Button, Typography } from "@mui/material";
import DatePicker from "./DatePicker.js";


export const DateDrawer = ({ toggleDrawer, setRange, range, calendarDrawerState }) => {

  const clearDates = () => {
    setRange();
  };

  return (
    <Drawer
      anchor={'top'}
      open={calendarDrawerState['top']}
      onClose={toggleDrawer('top', false)}
      style={{ justifyContent: 'center', width: '30%' }}
      sx={{ width: '30%' }}
    >
      <DatePicker range={range} setRange={setRange} />
      <div
        className="reset-date"
      >
        <Button size="small" onClick={clearDates}>
          <Typography sx={{ color: 'white', textDecoration: 'underline' }}>
            Clear
          </Typography>
        </Button>
      </div>
      <Button
        onClick={toggleDrawer('top', false)}
        sx={{backgroundColor:'#2c4b54', borderRadius: '0'}}
      >
        Close
      </Button>
    </Drawer>
  );

}