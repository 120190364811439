import { Grid } from "@mui/material";
// import DrawerComponent from "../DrawerComponent.js";
import { Calendar } from "./Calendar/Calendar.js";
import { SearchBar } from "./SearchBar.js";
// import SwipeableDrawer from '@mui/material/SwipeableDrawer';
// import { styled } from '@mui/material/styles';
// import { grey } from '@mui/material/colors';
// import { useState } from "react";
// import logo from '/logo512.png';

// const drawerBleeding = 56;

export const TripPlannerHeader = ({ setDefaultCenter, range, setRange, setLat, setLng, setSelectedPosition }) => {

  // const [open, setOpen] = useState(false);

  // const toggleDrawer = (newOpen) => () => {
  //   setOpen(newOpen);
  // };

  // const SwipeableDrawerStyled = styled(SwipeableDrawer)({
  //   "& .MuiDrawer-paper": {
  //     overflowY: "visible",
  //     backgroundColor: '#425b62'
  //   },
  // });

  // const Puller = styled('div')(({ theme }) => ({
  //   width: 30,
  //   height: 6,
  //   backgroundColor: theme.palette.mode === 'light' ? grey[300] : grey[900],
  //   borderRadius: 3,
  //   position: 'absolute',
  //   top: 8,
  //   left: 'calc(50% - 15px)',
  // }));

  return (
    <div>
      {/* <SwipeableDrawerStyled
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
      >
        <Puller />
      </SwipeableDrawerStyled> */}
      {/* <SwipeableDrawer
        anchor="top"
        open={open}
        onClose={toggleDrawer(false)}
        onOpen={toggleDrawer(true)}
        swipeAreaWidth={drawerBleeding}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        id="swipeabledrawer"
        sx={{ height: '50%' }}
      >
        <StyledBox
          sx={{
            position: 'absolute',
            top: -drawerBleeding,
            borderTopLeftRadius: 8,
            borderTopRightRadius: 8,
            visibility: 'visible',
            right: 0,
            left: 0,
            height: '50%'
          }}
          id="styledbox"
        >
          <Puller />
        </StyledBox>
        <StyledBox
          sx={{
            px: 2,
            pb: 2,
            height: '100%',
            overflow: 'auto',
          }}
        >
          <Skeleton variant="rectangular" height="100%" />
        </StyledBox>
      </SwipeableDrawer> */}
      <Grid container sx={{ flexWrap: 'nowrap', display: 'inline-flex', alignItems: 'center', justifyContent: 'center' }} >
        {/* <Grid item xs={1}>
          <DrawerComponent />
        </Grid> */}
        <Grid item xs={11}>
          <img src='/thesportsplannerlogosized.png' alt='logo'
            style={{ height: '40px', paddingTop: '5px' }}
          />
        </Grid>
      </Grid>

      <Grid container className="SearchAndDate" sx={{ flexWrap: 'nowrap', display: 'block', justifyContent: 'space-evenly'}} >
        <Grid item xs='true' sx={{ padding: '2px 0' }}>
          <div style={{ border: 'solid', borderWidth: '.1px', borderRadius: '5px' }} >
          <SearchBar setDefaultCenter={setDefaultCenter} setSelectedPosition={setSelectedPosition} setLat={setLat} setLng={setLng} />
          </div>
        </Grid>
        <Grid item xs='true' sx={{ padding: '2px 0' }}>
          <div style={{ border: 'solid', borderWidth: '.1px', borderRadius: '5px' }} >
          <Calendar setRange={setRange} range={range} />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}